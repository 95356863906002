<template>
    <div id="subtitle">
        <div class="subtitle" v-if="type=='short'">
            <div></div>
            <div>{{title}}</div>
            <div></div>
        </div>

        <div class="subtitle-plus" v-if="type=='long'">
            <div></div>
            <div>{{title}}</div>
            <div></div>
        </div>

        <div class="subtitle-small" v-if="type=='small-title'">
            <div>{{title}}</div>
        </div>
        
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
        },
        type: {
            type: String,
        },
    },
};
</script>

<style  scoped>
.subtitle {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

@media (min-width: 975px) {
    .subtitle > div:nth-child(1) {
        height: 2px;
        border: 1px solid rgb(221, 221, 221);
        width: 30px;
    }
    .subtitle > div:nth-child(2) {
        /* width: 300px; */
        font-size: 32px;
        font-weight: bold;
        margin: 0px 16px;
    }
    .subtitle > div:nth-child(3) {
        height: 2px;
        border: 1px solid rgb(221, 221, 221);
        width: 30px;
    }

    .subtitle-plus {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .subtitle-plus > div:nth-child(1) {
        height: 2px;
        border: 1px solid rgb(221, 221, 221);
        width: 40%;
    }
    .subtitle-plus > div:nth-child(2) {
        font-size: 32px;
        font-weight: bold;
        margin: 0px 16px;
    }
    .subtitle-plus > div:nth-child(3) {
        height: 2px;
        border: 1px solid rgb(221, 221, 221);
        width: 40%;
    }
    .subtitle-small {
        font-size: 24px;
        color: rgba(51, 51, 51, 1);
        margin-top: 99.5px;
    }
}

@media (max-width: 991px) {
    .subtitle > div:nth-child(1) {
        height: 2px;
        border: 1px solid rgb(221, 221, 221);
        width: 30px;
    }
    .subtitle > div:nth-child(2) {
        /* width: 300px; */
        font-size: 25px;
        font-weight: bold;
        margin: 0px 16px;
    }
    .subtitle > div:nth-child(3) {
        height: 2px;
        border: 1px solid rgb(221, 221, 221);
        width: 30px;
    }

    .subtitle-plus {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .subtitle-plus > div:nth-child(1) {
        height: 2px;
        border: 1px solid rgb(221, 221, 221);
        width: 40%;
    }
    .subtitle-plus > div:nth-child(2) {
        font-size: 25px;
        font-weight: bold;
        margin: 0px 16px;
    }
    .subtitle-plus > div:nth-child(3) {
        height: 2px;
        border: 1px solid rgb(221, 221, 221);
        width: 40%;
    }
    .subtitle-small {
        font-size: 24px;
        color: rgba(51, 51, 51, 1);
        margin-top: 44.5px;
    }
}

@media (max-width: 767px) {
    .subtitle > div:nth-child(1) {
        height: 2px;
        border: 1px solid rgb(221, 221, 221);
        width: 30px;
    }
    .subtitle > div:nth-child(2) {
        /* width: 300px; */
        font-size: 16px;
        font-weight: bold;
        margin: 0px 16px;
    }
    .subtitle > div:nth-child(3) {
        height: 2px;
        border: 1px solid rgb(221, 221, 221);
        width: 30px;
    }

    .subtitle-plus {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .subtitle-plus > div:nth-child(1) {
        height: 2px;
        border: 1px solid rgb(221, 221, 221);
        width: 35%;
    }
    .subtitle-plus > div:nth-child(2) {
        font-size: 16px;
        font-weight: bold;
        margin: 0px 16px;
    }
    .subtitle-plus > div:nth-child(3) {
        height: 2px;
        border: 1px solid rgb(221, 221, 221);
        width: 35%;
    }
    .subtitle-small {
        font-size: 16px;
        color: rgba(51, 51, 51, 1);
        margin-top: 20.5px;
    }
}
</style>