<template>
    <div class="wrapper">
        <swiper ref="mySwiper" :options="swiperOption" class="swiper-container">
            <swiperSlide class="swiper-item" v-for="(item,index) in cardList" :key="item.id">
                <div class="text-start Mycard" :style="item.state" :key="index" v-if="item.type!='immerse'" :class="isactive == index ? 'addclass' : '' " @click="goInformation(item.id)"
                    @mouseover="cardMouseOver(index)">
                    <img class="img-fluid" :src="item.img">
                    <p>{{item.title}}</p>
                    <p>
                        {{item.content}}
                    </p>
                    <p class="text-start">{{item.time}}</p>
                </div>
                <div class="Mycard-immerse" :key="index" :class="isactive == index ? 'addclass' : '' " @click="goInformation(item.id)" @mouseover="cardMouseOver(index)"
                    :style="{background: 'linear-gradient(180deg, rgba(61, 61, 61, 0) 0%,rgba(61, 61, 61, 0.2) 49.41%, rgba(61, 61, 61, 0.4) 54.64%,rgba(61, 61, 61, 0.6) 62.22%,rgba(61, 61, 61, 0.8) 80%,rgba(61, 61, 61, 1) 100%),url(' + item.img + ')',backgroundSize:'cover'}"
                    v-else>
                    <p>{{item.title}}</p>
                    <p>{{item.content}}</p>
                    <p>{{item.time}}</p>
                </div>
            </SwiperSlide>
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
        </Swiper>
    </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import SwiperCore, {
    Mousewheel,
    Navigation,
    EffectFade,
    EffectCoverflow,
} from "swiper/core"; //引入
import "swiper/swiper-bundle.css"; //样式地址与之前有不同
SwiperCore.use([Mousewheel, Navigation, EffectFade, EffectCoverflow]); // 使用
export default {
    data() {
        return {
            isactive: false,
            cardList: [
                {
                    id: "1",
                    title: "渥康品牌方参与中国市场营销 加码全球化战略布局",
                    content:
                        " 渥康将一如既往秉持品牌初心，持续打造高端乳品生态圈价值链，保障营养科学产品体系，释放品牌活力，提速中国消费市场乃至全球市场的占领进程，不遗余力守护全球消费者的健康品质生活。",
                    time: "2020-08-05",
                    img: require("../assets/images/card/01.png"),
                },
                {
                    id: "2",
                    type: "immerse",
                    title: "重磅推荐！2021年世界乳品创新奖的最佳乳零食奖的金奖产品，渥康豆诺豆娜乳豆",
                    content:
                        "把握绿色健康的消费趋势，唯有渥康这样的具有国际视野和研发实力的品牌商才可以做的到，渥康将持续加大创新研发力度，用更高的品质、更多样化的产品服务消费者，为乳制品行业发展创作新机遇，为乳制品零食开辟新空间。",
                    time: "2020-07-22",
                    img: require("../assets/images/card/02.png"),
                },
                {
                    id: "3",
                    title: "渥康品牌介绍",
                    content:
                        " 渥康（拉丁文：WDOM），新西兰高端乳品品牌。渥取意「丰盛」，康取意「富足」，渥康寓意「盛世繁华」。品牌所属集团公司旗下拥有众多生产工厂和牧场奶源，包括全球稀有的A2有机奶源和A2奶源，让渥康拥有研发高端乳品的硬实力。 ​​​",
                    time: "2020-08-05",
                    img: require("../assets/images/card/03.png"),
                },
            ],
            swiperOption: {
                spaceBetween: 10, //间距
                slidesPerView: 3,
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
                loop: false,
                speed: 1000,
            },
        };
    },
    components: {
        swiper,
        swiperSlide,
    },
    methods: {
        goInformation(id) {
            this.$router.push({
                path: "/information",
                query: {
                    id: id,
                },
            });
        },
        cardMouseOver(index) {
            this.isactive = index;
            console.log(index);
        },
    },
};
</script>

<style  scoped>
.swiper-button-prev {
    color: rgba(196, 196, 196, 1);
}
.swiper-button-next {
    color: rgba(196, 196, 196, 1);
}
.wrapper >>> .swiper-pagination-bullets {
    position: relative;
    top: 5px;
}

.wrapper >>> .swiper-pagination-bullet-active {
    background: rgba(196, 196, 196, 1);
}

.swiper-button-prev,
.swiper-button-next {
    position: absolute;
    top: 50%;
    width: calc(var(--swiper-navigation-size) / 44 * 27);
    height: var(--swiper-navigation-size);
    margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    /* color: var(--swiper-navigation-color, var(--swiper-theme-color)); */
}
.swiper-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.addclass {
    border-bottom: 2px solid rgba(255, 59, 59, 1);
    cursor: pointer;
}
.Mycard > img {
    border-radius: 10px;
}
.title-line {
    color: rgb(221, 221, 221);
}
@media (min-width: 975px) {
    .somethingNew {
        height: 350px;
        width: 100%;
        background-color: rgba(247, 247, 247, 1);
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }
    .title {
        font-size: 32px;
        font-weight: bold;
    }
    .Mycard {
        width: 342px;
        height: 512px;
        /* margin-left: 20px; */
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-radius: 10px;
    }

    .Mycard > img {
        height: 248px;
    }

    .Mycard > p:nth-child(2) {
        font-size: 20px;
        /* margin-top: 17px; */
    }

    .Mycard > p:nth-child(3) {
        font-size: 14px;
        /* margin-top: 49px; */
    }

    .Mycard > p:nth-child(4) {
        /* margin-top: 49px; */
        font-size: 12px;
    }

    .Mycard-immerse {
        /* margin-left: 20px; */
        width: 342px;
        height: 512px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        color: white;
        text-align: center;
        padding: 0px 50px;
    }

    .Mycard-immerse > p:nth-child(1) {
        font-size: 20px;
    }

    .Mycard-immerse > p:nth-child(2) {
        font-size: 14px;
        margin-top: 49px;
    }

    .Mycard-immerse > p:nth-child(3) {
        font-size: 12px;
        margin-top: 43px;
    }
    .About-wdom {
        background-color: rgba(247, 247, 247, 1);
        padding: 80px;
        text-align: left;
    }
    .About-wdom-position {
        position: relative;
        right: 60px;
    }
    .wrapper {
    margin: 50px 0px;
}
}

@media (max-width: 991px) {
    .Mycard-position {
        margin-left: 0px;
        width: 100%;
    }

    .title {
        font-size: 25px;
        font-weight: bold;
        margin-left: 10px;
    }

    .somethingNew {
        height: 100%;
        width: 100%;
        background-color: white;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }
    .Mycard {
        width: 100%;
        height: 550px;
        /* margin-top: 30px; */
    }

    /* .Mycard > img {
        width: 100%;
        height: 385px;
    } */

    .Mycard > p:nth-child(2) {
        font-size: 16px;
        margin-top: 17px;
    }

    .Mycard > p:nth-child(3) {
        font-size: 14px;
        margin-top: 20px;
    }

    .Mycard > p:nth-child(4) {
        font-size: 12px;
        margin-top: 20px;
    }

    .Mycard-immerse {
        width: 100%;
        height: 550px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        color: white;
        text-align: center;
        padding: 0px 10px;
        /* margin-top: 30px; */
    }

    .Mycard-immerse > p:nth-child(1) {
        font-size: 20px;
    }

    .Mycard-immerse > p:nth-child(2) {
        font-size: 14px;
        margin-top: 49px;
    }

    .Mycard-immerse > p:nth-child(3) {
        font-size: 12px;
        margin-top: 43px;
    }
    .About-wdom {
        text-align: left;
        padding: 20px;
        background-color: rgba(247, 247, 247, 1);
    }
    .wrapper {
    margin: 40px 0px;
}
}

@media (max-width: 767px) {
    .Mycard-position {
        margin-left: 0px;
        width: 100%;
    }

    .title {
        font-size: 16px;
        font-weight: bold;
        margin-left: 10px;
    }

    .somethingNew {
        height: 100%;
        width: 100%;
        background-color: white;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }
    .Mycard {
        width: 100%;
        height: 280px;
        /* margin-top: 30px; */
    }

    .Mycard > p:nth-child(2) {
        font-size: 14px;
        margin-top: 17px;
    }

    .Mycard > p:nth-child(3) {
        display: none;
    }

    .Mycard > p:nth-child(4) {
        font-size: 12px;
        margin-top: 20px;
    }

    .Mycard-immerse {
        width: 100%;
        height: 280px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        color: white;
        text-align: center;
        padding: 0px 20px;
    }

    .Mycard-immerse > p:nth-child(1) {
        font-size: 14px;
    }

    .Mycard-immerse > p:nth-child(2) {
        display: none;
    }

    .Mycard-immerse > p:nth-child(3) {
        font-size: 12px;
        margin-top: 43px;
    }
    .footer {
        display: flex;
        flex-direction: column;
        font-size: 10px;
    }
    .About-wdom {
        text-align: left;
        background-color: rgba(247, 247, 247, 1);
    }
    .swiper-button-prev:after,
    .swiper-button-next:after {
        font-family: swiper-icons;
        font-size: 30px;
        text-transform: none !important;
        letter-spacing: 0;
        text-transform: none;
        font-variant: initial;
        line-height: 1;
    }
    .wrapper {
    margin: 20px 0px;
}
}
</style>