<template>
    <div id="home">
        <div class="container-fluid">
            <swiper ref="mySwiper" :options="swiperOption" class="swiper-container">
                <swiperSlide class="swiper-item" v-for="item of swiperList" :key="item.id">
                    <img class="img-fluid" :src="item.imgUrl" />
                </swiperSlide>
            </swiper>
        </div>
        <div class="container">
            <div class="row d-flex align-items-baseline pt-5">
                <subtitle title="关于渥康" type="short"></subtitle>
            </div>
            <div class="row pt-5">
                <div class="col-md-5  About-wdom">
                   WDOM的品牌创始人，Mr. Ivan Clay艾尔文·格雷先生，是一位显赫的农场主，其家族在新西兰世代经营农场生意，他认为世代经营农场，提高牧场品质和产出是他们的家族使命，也是上天对他们家族的恩赐。WDOM一词源自拉丁文，其中DOM是「最好的、最伟大的」的缩写，这是艾尔文先生用古老的拉丁文字为品牌注入根深蒂固的品牌使命；品牌中文取名渥康，渥取意「丰盛」，康取意「富足」，渥康寓意「盛世繁华」。
                </div>
                <div class="col-md-6 d-flex align-items-center About-wdom-position">
                    <img src="../assets/images/card/04.png" class="img-fluid">
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row d-flex align-items-baseline pt-5">
                <subtitle title="渥康产品" type="short"></subtitle>
            </div>
            <carousel-by-products></carousel-by-products>
        </div>
        <div class="container px-1 somethingNew">
            <div class="row title pt-5">
                <div class="row fw-bold">
                    <div>WDOM新鲜事</div>
                </div>
            </div>
            <my-card></my-card>
        </div>
    </div>
</template>

<script>
import subtitle from '../components/subtitle'
import CarouselByProducts from "../components/CarouselByProducts";
import myCard from "../components/myCard";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import SwiperCore, { Autoplay } from "swiper/core";
import "swiper/swiper-bundle.css";
SwiperCore.use([Autoplay]);
export default {
    name: "Home",
    data() {
        return {
            swiperOption: {
                effect: "fade",
                slidesPerView: 1,
                loop: true,
                autoplay: {
                    delay: 2500,
                    disableOnInteraction: false,
                },
                //滑动速度
                speed: 1000,
            },
            swiperList: [
                {
                    id: 0,
                    imgUrl: require("../assets/images/Exhibition/indexExhibition-1.png"),
                },
                {
                    id: 1,
                    imgUrl: require("../assets/images/Exhibition/indexExhibition-2.png"),
                },
            ],
        };
    },

    components: {
        CarouselByProducts,
        myCard,
        swiper,
        swiperSlide,
        subtitle
    },
};
</script>

<style scoped>
.addclass {
    border-bottom: 2px solid rgba(255, 59, 59, 1);
    cursor: pointer;
}
.Mycard > img {
    border-radius: 10px;
}
@media (min-width: 975px) {
    /* .somethingNew {
        height: 350px;
        width: 100%;
        background-color: rgba(247, 247, 247, 1);
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        margin-bottom: 600px;
    } */

    .title {
        font-size: 32px;
        font-weight: bold;
    }
    .About-wdom {
        background-color: rgba(247, 247, 247, 1);
        /* padding: 80px; */
        text-align: left;
        font-size: 28px;
        width: 645px;
        height: 630px;
        padding: 69px 81px;
        word-break:break-all;
    }
    /* .About-wdom-position {
        position: relative;
        right: 60px;
    } */
    .About-wdom-position>img{
        width: 624px;
        height: 468px;
    }
    .title-line {
        font-size: 18px;
        position: relative;
        bottom: 5px;
        color: rgb(221, 221, 221);
    }
}

@media (max-width: 991px) {
    .title-line {
    font-size: 14px;
    position: relative;
    bottom: 5px;
    color: rgb(221, 221, 221);
}
    .Mycard-position {
        margin-left: 0px;
        width: 100%;
    }

    .title {
        font-size: 25px;
        font-weight: bold;
        margin-left: 10px;
    }

    .About-wdom {
        text-align: left;
        padding: 20px;
        background-color: rgba(247, 247, 247, 1);
    }
}
@media (max-width: 767px) {
    .title-line {
        font-size: 10px;
        color: rgb(221, 221, 221);
    }
    .somethingNew {
        height: 450px;
        width: 100%;
        background-color: rgba(247, 247, 247, 1);
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }
    .title {
        font-size: 16px;
        font-weight: bold;
        margin-left: 10px;
    }
    .footer {
        display: flex;
        flex-direction: column;
        font-size: 10px;
    }
    .About-wdom {
        text-align: left;
        background-color: rgba(247, 247, 247, 1);
    }
}
</style>