<template>
  <div class="wrapper">
    <swiper v-if="swiperList.length>1" ref="mySwiper" :options="swiperOption" class="swiper-container">
      <swiperSlide class="swiper-item pointer" v-for="item of swiperList" :key="item.id">
        <img @click="goProductList(item.id)" class="img-fluid" :src="item.imgUrl"/>
        <p @click="goProductList(item.id)" class="title">{{ item.title }}</p>
      </swiperSlide>
      <div class="swiper-pagination" slot="pagination"></div>
      <div class="swiper-button-prev" slot="button-prev"></div>
      <div class="swiper-button-next" slot="button-next"></div>
    </swiper>
  </div>
</template>

<script>
import {swiper, swiperSlide} from "vue-awesome-swiper";
import SwiperCore, {
  Pagination,
  Mousewheel,
  Navigation,
  EffectFade,
  EffectCoverflow,
} from "swiper/core"; //引入
import "swiper/swiper-bundle.css"; //样式地址与之前有不同
SwiperCore.use([
  Pagination,
  Mousewheel,
  Navigation,
  EffectFade,
  EffectCoverflow,
]); // 使用
export default {
  data() {
    return {
      swiperOption: {
        //slidesPerView: 3,//展示三个
        //slidesPerColumn: 2,//行数
        spaceBetween: 30, //间距
        //effect:'fade',//渐变效果
        // effect: 'coverflow',
        // grabCursor: true,
        // centeredSlides: true,
        slidesPerView: 1,
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        // loop: true,
        // autoplay: {
        //   delay: 2000,
        //   disableOnInteraction: false,
        // },
        //滑动速度
        speed: 1000,
      },
      swiperList: [
        {
          id: "0",
          imgUrl: require("../assets/images/Exhibition/series_1.png"),
          title: "渥康纯奶系列",
        },
        {
          id: "1",
          imgUrl: require("../assets/images/Exhibition/series_3.png"),
          title: "渥康功能系列",
        },
        {
          id: "2",
          imgUrl: require("../assets/images/Exhibition/series_2.png"),
          title: "渥康有机系列",
        },
        {
          id: "3",
          imgUrl: require("../assets/images/Exhibition/series_6.png"),
          title: "渥康极速系列",
        },
        {
          id: "4",
          imgUrl: require("../assets/images/Exhibition/series_4.png"),
          title: "渥康奶粉系列",
        },
        {
          id: "5",
          imgUrl: require("../assets/images/Exhibition/series_5_1.jpeg"),
          title: "渥康休闲系列",
        },
      ],
    };
  },
  methods: {
    goProductList(tid) {
      this.$router.push({
        path: "/ProductList",
        query: {
          tid: tid
        }
      });
    }
  },
  components: {
    swiper,
    swiperSlide,
  },
};
</script>

<style scoped>
.wrapper {
  margin: 50px 0px;
}

.pointer {
  cursor: pointer
}

.swiper-button-prev {
  color: rgba(196, 196, 196, 1);
}

.swiper-button-next {
  color: rgba(196, 196, 196, 1);
}

.wrapper >>> .swiper-pagination-bullets {
  position: relative;
  top: 5px;
}

.wrapper >>> .swiper-pagination-bullet {
  width: 16px;
  height: 16px;
  background: #fff;
  border: 3px solid rgba(196, 196, 196, 1);
  margin-left: 14px;
}

.wrapper >>> .swiper-pagination-bullet-active {
  background: rgba(196, 196, 196, 1);
}

.SwiperSlide-title {
  margin-top: 57px;
  margin-bottom: 38px;
  font-size: 28px;
}

.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 30%;
  width: calc(var(--swiper-navigation-size) / 44 * 27);
  height: var(--swiper-navigation-size);
  margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  /* color: var(--swiper-navigation-color, var(--swiper-theme-color)); */
}

@media (min-width: 975px) {
  .title {
    font-size: 32px;
    font-weight: bold;
    margin-top: 57px;
    margin-bottom: 38px;
  }

  .swiper-item > img {
    width: 720px;
    height: 400px;
  }
}

@media (max-width: 991px) {
  .title {
    font-size: 25px;
    font-weight: bold;
    margin-left: 10px;
    margin-top: 57px;
    margin-bottom: 38px;
  }

  .swiper-item > img {
    width: 520px;
    height: 250px;
  }
}

@media (max-width: 767px) {
  .title {
    font-size: 16px;
    font-weight: bold;
    margin-left: 10px;
    margin-top: 37px;
    margin-bottom: 18px;
  }

  .swiper-button-prev:after,
  .swiper-button-next:after {
    font-family: swiper-icons;
    font-size: 30px;
    text-transform: none !important;
    letter-spacing: 0;
    text-transform: none;
    font-variant: initial;
    line-height: 1;
  }

  .wrapper >>> .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background: #fff;
    border: 1px solid rgba(196, 196, 196, 1);
    margin-left: 14px;
  }

  .swiper-item > img {
    width: 250px;
    height: 130px;
  }
}
</style>